/* width and height of the scrollbar */
*::-webkit-scrollbar {
  /* width: 8px;
  height: 8px; */
  width: 0px;
  height: 0px;
}

/* Track */
*::-webkit-scrollbar-track {
  background: #fafafa;
  border-radius: 10px;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: #f5f5f5;
  border-radius: 10px;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  background: #f5f5f5;
}

/* Remove border from the Quill text input area */
.ql-container {
  border: none !important;
}

.ql-toolbar {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #fafafa;
  border-bottom: 1px solid #e5e5e5 !important;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
}

.ql-container.ql-snow {
  height: auto;
}
.ql-editor {
  font-family: "Inclusive Sans", sans-serif !important;
  font-size: 1.5em;
  overflow-y: scroll;
  /* border: 2px solid green; */
  margin-bottom: 50px;
  height: 430px;
}

@media (max-width: 430px) {
  .ql-editor {
    height: 600px; /* Phones in Portrait */
  }
}

@media (max-width: 667px) and (orientation: landscape) {
  .ql-editor {
    height: 175px; /* Smaller devices in landscape, like iPhone SE */
  }
}

@media (min-width: 844px) and (orientation: landscape) {
  .ql-editor {
    height: 210px; /* Smaller devices in landscape, like iPhone 12 */
  }
}

@media (min-width: 992px) {
  .ql-editor {
    height: 450px; /* Large devices, desktops */
  }
}
